import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
 
} from "../../../components/_index";
import "../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";


const interviewData = [
  {
    src:"/assets/images/recruit/interview/interview.png",
    url_src:"/recruit/interview/article01/",
    alt:"",
    title:[
      {
        title:"「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。",
      },
      {
        title:"入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感",
      },
    ],
    subtitle:[
      {
        title:"2015年 新卒入社",
      },
      {
        title: <>ザ ロイヤルパークホテル アイコニック 東京汐留 <br className="u-PC"/> フロントチーム</>,
      },
    ],
  },
  {
    src:"/assets/images/recruit/interview/interview2.png",
    url_src:"/recruit/interview/article02/",
    alt:"",
    title:[
      {
        title:"お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。",
      },
      {
        title:<>価値ある取り組みを紹介する<br />「真心感動ボード」も仕事への意欲に</>,
      },
    ],
    subtitle:[
      {
        title:"2020年 新卒入社",
      },
      {
        title:"丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      },
    ],
  },
  {
    src:"/assets/images/recruit/interview/interview3.png",
    url_src:"/recruit/interview/article03/",
    alt:"",
    title:[
      {
        title:<>「さまざまな接客を経験したい」と、<br className="u_pc"/>これまで経験のないフロント業務が<br className="u_pc"/>できる当社へ。</>,
      },
      {
        title:<>ライフスタイルホテルの最高峰を<br className="u_pc"/>目指し、<br className="u_sp"/>若いスタッフの意見を採り入れて<br className="u_sp"/>イベントを実施する</>,
      },
    ],
    subtitle:[
      {
        title:"2019年 キャリア入社",
      },
      {
        title:"ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      },
    ],
  },
  {
    src:"/assets/images/recruit/interview/interview4.png",
    url_src:"/recruit/interview/article04/",
    alt:"",
    title:[
      {
        title:<>学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと<br className="u_pc"/>当社へ就職。<br className="u_sp"/>お客さまやスタッフとの<br className="u_pc"/>コミュニケーションを<br className="u_sp"/>高め、おもてなしの最高峰を極める</>,
      },
    ],
    subtitle:[
      {
        title:"2020年 新卒入社",
      },
      {
        title:<>ザ ロイヤルパークホテル アイコニック 大阪御堂筋 <br className="u-PC"/>フロントチーム</>,
      },
    ],
  },
  {
    src:"/assets/images/recruit/interview/interview5.png",
    url_src:"/recruit/interview/article05/",
    alt:"",
    title:[
      {
        title:"作る料理に一切の妥協をしない。",
      },
      {
        title:<>コンクールにも挑戦し、自分の武器を<br className="u_pc"/>増やしながら成長を重ねる毎日</>,
      },
    ],
    subtitle:[
      {
        title:"2020年 新卒入社",
      },
      {
        title:<>ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課<br />バンケットコールド調理</>
      },
    ],
  },
  {
    src:"/assets/images/recruit/interview/interview6.png",
    url_src:"/recruit/interview/article06/",
    alt:"",
    title:[
      {
        title:"「不採用でもあきらめない」",
      },
      {
        title:"子どもの頃の憧れを形に掴んだキャリア",
      },
    ],
    subtitle:[
      {
        title:"2020年 キャリア入社",
      },
      {
        title:"仙台ロイヤルパークホテル 婚礼",
      },
    ],
  },
]



const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "社員インタビュー",
    content: "Member Interview",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect05 mb-10 recruit-font">
        <LWrap2>
        {/* <LWrap> */}
        <div className="new_main">
          <ContainerCrumbs data={crumbsData} />
          <div className="container_main container_main_1280">
            <Vertical data={verticalData} />
            <InterviewPic data={interviewData}  exClass="right_interview3" />
            
          </div>
        </div>
        </LWrap2>
      </section>
 
  
 
  
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
